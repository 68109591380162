.chip {
  --offset: 17.5px;
  --smallOffset: 12px;
  --negativeOffset: -17.5px;
  --negativeSmallOffset: -12px;
}

.chip {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chip.center {
  inset: 0;
  margin: auto;
}

.chip.right-top {
  top: var(--negativeOffset);
  right: var(--negativeOffset);
}

.chip.right-bottom {
  bottom: var(--negativeOffset);
  right: var(--negativeOffset);
}

.chip.center-top {
  top: var(--negativeOffset);
  left: 0;
  right: 0;
  margin: auto;
}

.chip.right-top-with-offset {
  top: 20px;
  right: var(--negativeOffset);
}

.chip.right-bottom-with-offset {
  bottom: 20px;
  right: var(--negativeOffset);
}

.chip.right-bottom-with-no-offset {
  bottom: 0px;
  right: var(--negativeOffset);
}

.chip.right-top-with-no-offset {
  top: 0px;
  right: var(--negativeOffset);
}

.chip.center-bottom {
  bottom: var(--negativeOffset);
  left: 0;
  right: 0;
  margin: auto;
}

.chip.left-top {
  top: var(--negativeOffset);
  left: var(--negativeOffset);
}

.chip.right-center {
  right: var(--negativeOffset);
  top: 0;
  bottom: 0;
  margin: auto;
}

/* sm */
@media (max-width: 640px) {
  .chip {
    width: 20px;
    height: 20px;
  }

  .chip.right-top {
    top: var(--negativeSmallOffset);
    right: var(--negativeSmallOffset);
  }

  .chip.right-bottom {
    bottom: var(--negativeSmallOffset);
    right: var(--negativeSmallOffset);
  }

  .chip.right-top-with-offset {
    right: var(--negativeSmallOffset);
  }

  .chip.right-bottom-with-offset {
    right: var(--negativeSmallOffset);
  }

  .chip.right-bottom-with-no-offset {
    right: var(--negativeSmallOffset);
  }

  .chip.right-top-with-no-offset {
    right: var(--negativeSmallOffset);
  }

  .chip.center-bottom {
    bottom: var(--negativeSmallOffset);
  }

  .chip.left-top {
    top: var(--negativeSmallOffset);
    left: var(--negativeSmallOffset);
  }

  .chip.right-center {
    right: var(--negativeSmallOffset);
  }
}
