/* fonts - https://web.mit.edu/jmorzins/www/fonts.html */
/* TODO: move to css modules */

.roulette-table-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.roulette-table-container {
	--red: #cc000e;
	--black: #000;
	--hover: rgba(126,220,70);
	font-size: 18px;
	font-weight: 400;
	font-family: Helvetica, sans-serif;
	line-height: 1.14;
}

.roulette-table-container-first {
  position: relative;
	display: flex;
	flex-flow: column-reverse wrap;
	align-content: flex-start;
	height: 228px;
}

.split-up-bet-catcher-top,
.split-up-bet-catcher-right,
.split-up-bet-catcher-bottom,
.spleet-bet-catcher,
.corner-bet-catcher,
.six-lines-catcher,
.basket-catcher-top,
.basket-catcher-bottom,
.double-street-catcher-top-right {
  position: absolute;
  z-index: 11;
}

.spleet-bet-catcher,
.corner-bet-catcher,
.six-lines-catcher,
.double-street-catcher-top-right {
	width: 30px;
	height: 30px;
	z-index: 12;
}

.roulette-table-container.debug .split-up-bet-catcher-top,
.roulette-table-container.debug .split-up-bet-catcher-right,
.roulette-table-container.debug .split-up-bet-catcher-bottom,
.roulette-table-container.debug .spleet-bet-catcher,
.roulette-table-container.debug .corner-bet-catcher,
.roulette-table-container.debug .six-lines-catcher,
.roulette-table-container.debug .basket-catcher-top,
.roulette-table-container.debug .basket-catcher-bottom,
.roulette-table-container.debug .double-street-catcher-top-right {
	background-color: royalblue;
}

.split-up-bet-catcher-top {
  top: -3px;
  width: 100%;
  height: calc(3px + 2px);
}

.split-up-bet-catcher-right {
  top: 0;
  bottom: 0;
  right: -3px;
  width: calc(3px + 2px);
  height: 100%;
}

.split-up-bet-catcher-bottom {
  bottom: -3px;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(3px + 2px);
}

.spleet-bet-catcher {
  border-radius: 50%;
  top: -15px;
  left: -15px;
}

.corner-bet-catcher {
  border-radius: 50%;
  top: -15px;
  right: -15px;
}

.corner-bet-catcher.bottom {
  top: auto;
  bottom: -15px;
}

.six-lines-catcher,
.double-street-catcher-top-right {
  border-radius: 50%;
}

.six-lines-catcher {
  bottom: -15px;
  right: -15px;
}

.double-street-catcher-top-right {
	top: -15px;
	right: -15px;
}

.basket-catcher-top,
.basket-catcher-bottom {
	width: 100%;
	height: 5px;
}

.basket-catcher-top {
	top: -5px;
}

.basket-catcher-bottom {
	bottom: -5px;
}

.red-item, .black-item, .zero-item {
  position: relative;
  border: none;
  border-left: 3px solid #fff;
  border-top: 3px solid #fff;
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
	/* width: 50px; */
	width: 7.14%;
	/* font-size: 30px; */
	color: #fff;
}
.red-item {
	background-color: var(--red);
}

.black-item {
	background: var(--black);
}

.zero-item:first-child {
  border-bottom: 1px solid #fff;
	margin-bottom: -1px;
}

.zero-item {
	/* background-color: green; */
  /* border-bottom: 3px solid white; */
	/* padding-top: 110px;
	padding-bottom: 111px; */
  padding-top: 50px;
  padding-bottom: 36.8px;
}

.item-hover,
.zero-item,
.red-item,
.black-item,
.column-item,
.doz-item,
.outside-section {
  transition: background-color 0.2s ease;
}

.item-hover,
.zero-item:hover,
.red-item:hover,
.black-item:hover
/*
.column-item:hover,
.doz-item:hover,
.outside-section:hover*/ {
  background-color: var(--hover);
  cursor: pointer;
}

.column-item {
	position: relative;
	border: 3px solid #fff;
	border-top: none;
	/* background-color: green; */
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
	/* width: 50px; */
	width: 7.14%;
	/* font-size: 30px; */
	color: #fff;
}

/* to change this way */
.column-item[data-action="1ST_COLUMN"] {
	border-bottom: 1px solid #fff;
	margin-bottom: -1px;
}

.column-item:last-child {
	border-top: 3px solid #fff;
}

.roulette-table-container-second {
	display: flex;
	justify-content: center;
	/* background-color: green; */
	width: calc(100% - 7.14% - 7.14%);
	margin: 0 auto;
	height: 70px;
	flex-flow: row wrap;
	align-content: flex-start;
}

.doz-item {
	position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
	flex: 1;
	/* flex-basis: 220px; */
	border: solid #fff;
	/* background-color: green; */
	/* padding-top: 15px; */
	/* padding-bottom: 30px; */
	text-align: center;
	height: 100%;
	/* font-size: 30px; */
	color: #fff;
}

.roulette-table-container-third {
	display: flex;
	/* background-color: green; */
	width: calc(100% - 7.14% - 7.14%);
	margin: 0 auto;
	/* height: 150px; */
	flex-flow: row wrap;
	align-content: flex-start;
}

.outside-section {
	position: relative;
	/* flex-basis: 107px; */
	flex: 1;
	border: solid #fff;
	/* background-color: green; */
	/* padding-top: 15px;
	padding-bottom: 30px; */
	text-align: center;
	height: 70px;
	/* font-size: 30px; */
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rhomb-red {
	display: inline-block;
	width: 30px;
	height: 30px;
	background-color: var(--red);
	border: solid white;
	transform: rotate(45deg);
}

.rhomb-black {
	display: inline-block;
	width: 30px;
	height: 30px;
	background-color: #000;
	border: solid white;
	transform: rotate(45deg);
}

.value {
	transform: rotate(-90deg);
}

/* sm */
@media (max-width: 640px) {
	.roulette-table-container {
		font-size: 18px;
	}

	.zero-item {
		padding-bottom: 35.3px;
	}

	.roulette-table-container-second {
		height: 50px;
	}

	.outside-section {
		height: 60px;
	}
}
